.vertical-progress-bar {
    color: black;
    margin: 5px 0px 5px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    width: 1rem;
    height: 7rem;
    align-items: center;
    justify-content: center;
}

.vertical-progress-bar__bar {
    display: flex;
    width: 0.75rem;
    height: 6rem;
    border: 1px solid #4b4b4b;
    background-color: #b182c7;
    border-radius: 12px;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
}

.vertical-progress-bar__progress {
    width: 0.75rem;
    height: 0%;
    background-color: #40005d;
    border-radius: 0px 0px 12px 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    transition: 0.5s ease;
}

.vertical-progress-bar__label {
    font-size: 0.75rem;
    font-weight: bold;
}